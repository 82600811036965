@font-face{
  font-family: "Aktiv Grotesk Light";

  src: url("./fonts/Web1Yr_AktivGroteskEx_Lt/AktivGroteskEx_W_Lt.eot");
  src:
  url("./fonts/Web1Yr_AktivGroteskEx_Lt/AktivGroteskEx_W_Lt.woff") format("woff")
}
@font-face{
  font-family: "Aktiv Grotesk Extended Bold";

  src: url("./fonts/Web1Yr_AktivGroteskEx_XBd/AktivGroteskEx_W_XBd.eot");
  src:
  url("./fonts/Web1Yr_AktivGroteskEx_XBd/AktivGroteskEx_W_XBd.woff") format("woff")
}
.modalShadow{
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .5);
}
body {
  margin: 0;
  font-family: "Aktiv Grotesk Light";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#logoImage{
  background-color:#234d61;
  width: 235px;
}
.navbar{
  background-color:#234d61;
  font-family: "Aktiv Grotesk Extended Bold";

}
.btn-light{
  margin: .25em;
  align-items: center;
  

}
.navbar-nav a{
  background-color: #ECFBFF;
  padding-bottom:.65rem;
  padding-top:.65rem;
  font-size: .95rem;

  text-align: center;
}

.navbar-nav{
  background-color:#234d61;

}
button.navbar-toggler.collapsed{
  outline:none;
}
.navbar-light .navbar-toggler{
  border-style: none;
}
#logoImage{
  width:250px;
  height:52.65px;
  padding-bottom:0px;
}
.navbar{
  padding-top:0;
}
.clipboard{
  height: 80%;
  width: 33.5%;
  background-color:#FF6C35;
  padding:1.5rem;
  border-radius: .25rem;
  z-index: 100;
  position: absolute;
}

.clipBoard-retracted{
  margin-top:-87%;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.clipboard-showing{
  margin-top:.25rem;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.form{  
  background-color:#ECFBFF;
  border-radius:.25rem;
  height:100%;
  align-items: center;
  width: 100%;
  overflow-y:scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.form::-webkit-scrollbar {
  display: none;
}
#root{
  background-color: #ECFBFF;
}
#logInSubmit, #resetSubmit, #registerSubmit, #LogOut, #addPrescriptions, #checkInteractionsSubmit, #checkSymptomsSubmit, #resetPassword, #newPassword, #incorrectLoginDismiss, #registrationIncompleteLogin, #unableToResetPasswordButton, #thankYouForRegisteringButton, #youAreAlreadyRegisteredButton, #youHaveNotYetRegisteredButton, #completeYourRegistrationButton, #registrationNeedsCompletion, #somethingWentWrongWithYourRegistrationButton, #resetConfirmationClipboardButton, #resetPasswordFailureButton, #errorForResettingPasswordButton, #checkInteractionResponsesDismiss, #displayInteractionsCheck, #checkSymptomsDataDismiss, #privacyPolicyAgreement, #feedbackOnPrivacyPolicyAgreement, #feedbackOnPrivacyPolicyAgreementRegistering, #privacyPolicyDisagree, #noRegisteredUser, #logOutForDisagreement{
  background-color: #FF6C35;
  border-color: transparent;
  font-family: "Aktiv Grotesk Extended Bold";
  margin-top: 1em;
  margin-bottom: 1em;
  display: block;
}
.warningTextOnUserInput{
  background-color: #FF6C35;
  border-radius: .25rem;
  z-index:1000;
  width:75%;
  padding:.5rem;
  margin-top: .5rem;
  color:#fff;
  font-family: "Aktiv Grotesk Extended Bold";
  margin-right:auto;
  margin-left:auto;
}

#highSeverity{
  background-color:#ff0000;
  border-color: transparent;
  font-family: "Aktiv Grotesk Extended Bold";
}
#userEmail, #userPassword, #resetEmail, #email2, #registerPassword, #firstName, #lastName, #newpassword, #resettingUserEmail{
  background-color: #ECFBFF;
  color:#2D84AD;
}

/*You can modify the drugs and labels inputs here.*/
#drug1, #drug2, #drug3, #drug4, #drug5, #drug6, #drug7, #drug8, #drug9, #drug10, #drug11, #drug12,#drug13,#drug14,#drug15, #drug16, #drug17,#drug18, #drug19,#drug20, #drug21, #drug22,#drug23,#drug24,#drug25, #drug26, #drug27,#drug28,#drug29,#drug30{
  background-color: #ECFBFF;
  color:#2D84AD;
}
label[for="drug1"], label[for="drug2"], label[for="drug3"], label[for="drug4"], label[for="drug5"],label[for="drug6"], label[for="drug7"], label[for="drug8"],label[for="drug9"],label[for="drug10"],label[for="drug11"], label[for="drug12"],label[for="drug13"],label[for="drug14"],label[for="drug15"],label[for="drug16"],label[for="drug17"], label[for="drug18"], label[for="drug19"],label[for="drug20"], label[for="drug21"], label[for="drug22"],label[for="drug23"], label[for="drug24"], label[for="drug25"], label[for="drug26"], label[for="drug27"], label[for="drug28"],label[for="drug29"], label[for="drug30"]{
  color:#2D84AD;
  font-family: "Aktiv Grotesk Light";
}

label[for="userEmail"], label[for="userPassword"], label[for="resetEmail"], label[for="email2"], label[for="registerPassword"], label[for="firstName"], label[for="lastName"], label[for="newpassword"], label[for="newUserPassword"], label[for="resettingUserEmail"] {
  color:#2D84AD;
  font-family: "Aktiv Grotesk Light";
}
.clipboardClip{
  background-color: #2D84AD;
  width:75%;
  height:2em;
  border-radius: 1em;
  position: absolute;
  margin-top:-.75em;
  margin-left:7%;
  margin-right:7%;

}
.clipboardBend{
  background-color: white;
  height:.05em;
  width:60%; 
  margin-left:auto;
  margin-right:auto;
}
/*adjust this at smaller screen sizes*/
.centeredPaper{
  width:60%;
  margin-left: auto;
  margin-right:auto;
  padding-top: 1em;
  
}
.centeredForPageSwitchesPaper{
  width:55%;
  margin-left: auto;
  margin-right:auto;
  padding-top: 1em;
  
}
.paperUp{
  border-radius:.25rem;
  width:85%;
  margin-left: auto;
  margin-right:auto;
  padding-top: 1em;
  background-color:#ECFBFF;
}
.paperOut{
  border-radius:.25rem;
  width:85%;
  margin-left: auto;
  margin-right:auto;
  background-color:#ECFBFF;
  padding-top: 1em;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  margin-top:190%;
  z-index: 1000;
  position: absolute;

}
.paperIn{
  border-radius:.25rem;
  width:85%;
  margin-left: auto;
  margin-right:auto;
  background-color:#ECFBFF;
  padding-top: 1em;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  z-index: -1000;
  position: absolute;
}
.clipboardTop{
  background-color: #2D84AD;
  width:1.5em;
  height:1.5em;
  margin-left:auto;
  margin-right:auto;
  margin-top: -.65em;
  border-radius: 1.5em;
}
.form-group{
  margin-top:2em;
  margin-bottom:2em;
  cursor:'pointer'
}

.transitionClipboardOut {
  transition: transform 1000ms ease-in-out;
}
.aboutTheSite{
  width:70%;
  margin-right:auto;
  margin-left:auto;
}
.landingHeading{
  margin-left:15%;
}
@media (max-width: 991px) {
  .clipboard{
    height: 100%;
    width: 99%;
    margin-left: .25rem;
    margin-right: .25rem;
    margin-bottom: .25rem;
  }
  .clipBoard-retracted{
    margin-top:-154%;
  }
  .clipboard-showing{
    margin-top:.25rem;
  }
  .aboutTheSite{
    width:80%;
    margin-right:auto;
    margin-left:auto;
  }
  .landingHeading{
    margin-left:10%;
  }
}
@media (max-width: 800px){
  .clipBoard-retracted{
    margin-top:-180%;
  }
  .paperOut{
    width:85%;
    margin-top:150%;
    height:100%;
  }
  .aboutTheSite{
    width:85%;
    margin-right:auto;
    margin-left:auto;
  }
  .landingHeading{
    margin-left:7.5%;
  }
}
@media (max-width: 690px){
  .clipBoard-retracted{
    margin-top:-207%;
  }
  .clipboard-showing{
    margin-top:.25rem;
  }
  .paperOut{
    margin-top:135%;
  }
  .aboutTheSite{
    width:85%;
    margin-right:auto;
    margin-left:auto;
  }
  .landingHeading{
    margin-left:7.5%;
  }
}
@media (max-width: 600px){
  .clipBoard-retracted{
    margin-top:-251%;
  }
  .paperUp{
    width:85%;
  }
  .paperOut{
    width:85%;
    margin-top:250%;
    height:100%;
  }
  .paperIn{
    width:85%;
  }
  .aboutTheSite{
    width:80%;
    margin-right:auto;
    margin-left:auto;
  }
  .landingHeading{
    margin-left:10%;
  }
}
@media(max-width:480px){
  .clipBoard-retracted{
    margin-top:-301%;
  }
  .aboutTheSite{
    width:50%;
    margin-right:auto;
    margin-left:auto;
  }
  .aboutTheSite{
    width:85%;
    margin-right:auto;
    margin-left:auto;
  }
  .landingHeading{
    margin-left:7.5%;
  }
}
@media(max-width:400px){
  .clipBoard-retracted{
    margin-top:-355%;
  }
  .aboutTheSite{
    width:90%;
    margin-right:auto;
    margin-left:auto;
  }
  .landingHeading{
    margin-left:5%;
  }
}
@media(max-width:350px){
  .clipBoard-retracted{
    margin-top:-388%;
  }
  .aboutTheSite{
    width:80%;
    margin-right:auto;
    margin-left:auto;
  }
  .aboutTheSite{
    width:95%;
    margin-right:auto;
    margin-left:auto;
  }
  .landingHeading{
    margin-left:2.5%;
  }
}
@media(max-width: 320px){
  .clipBoard-retracted{
    margin-top:-270%;
  }
  #logoImage{
    width:196px;
  }
  .form-group{
    margin-top:1em;
    margin-bottom:0em;
  }
  .aboutTheSite{
    width:95%;
    margin-right:auto;
    margin-left:auto;
  }
  .landingHeading{
    margin-left:2.5%;
  }
}
