.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #ECFBFF;
  min-height: 100vh;
  display: flex;
  font-family: "Aktiv Grotesk Light";
  color: black;
}
